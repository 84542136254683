import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { Dropdown,
  DropdownMenu,
  DropdownItem } from 'element-ui';

import '@/assets/common.css'
import "@/utils/flexible";

Vue.config.productionTip = false
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(DropdownItem.name, DropdownItem);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
