import Vue from "vue";
import Router from "vue-router";

const routes = [
  {
    path: "/",
    name: "HOME_MAIN",
    component: () => import("@/view/main"),
  },
  {
    path: "/about",
    name: "HOME_ABOUT",
    component: () => import("@/view/about"),

  },
  {
    path: "/introduce",
    name: "HOME_INTRODUCE",
    component: () => import("@/view/introduce"),

  },
  {
    path: "/notice",
    name: "HOME_NEW",
    component: () => import("@/view/notice"),

  },
  {
    path: "/scene",
    name: "HOME_SCENCE",
    component: () => import("@/view/scene"),
  },
  {
    path: "/software",
    name: "HOME_SOFTWARE",
    component: () => import("@/view/software"),
  },
  {
    path: "/special",
    name: "HOME_SPECIAL",
    component: () => import("@/view/special"),
    meta: { keepAlive: true }
  },
  {
    path: "/special_cc",
    name: "HOME_SPECIAL_CC",
    component: () => import("@/view/cc"),
  },
  {
    path: "/special_ggc",
    name: "HOME_SPECIAL_GGC",
    component: () => import("@/view/ggc"),
  },

  {
    path: "/special_ydcn",
    name: "HOME_SPECIAL_YDCN",
    component: () => import("@/view/ydcn"),
  },

  {
    path: "/special_zckt",
    name: "HOME_SPECIAL_ZCKT",
    component: () => import("@/view/zckt"),
  },

  {
    path: "/special_agv",
    name: "HOME_SPECIAL_AGV",
    component: () => import("@/view/agv"),
  },
];
Vue.use(Router);

const router = new Router({
  routes,
  mode: "history",
});

export default router;
